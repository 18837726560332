import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import SecondaryButton from "../../buttons/secondary_button"
import SingleCard from "../../cards/single_card"

const texts = {
  pt: [
    "Recursos importantes que podem te auxiliar na revisão sistemática da literatura",
    "Desde a elaboração de uma pergunta de revisão até a publicação dos resultados da revisão",
    "Ver mais",
    "Guia PICO (Português)",
    "Um guia de uso da ferramenta mnemônica PICO para elaboração de uma pergunta de revisão, busca de literatura e seleção de estudos.",
    "Guia RoB-Syrcle (Português)",
    "Um guia de uso da ferramenta RoB-Syrcle para avaliação da qualidade interna dos estudos incluídos na revisão sistemática da literatura.",
    "/ferramentas-e-recursos"
  ],
  en: [
    "Important resources that can assist you in the systematic review of the literature.",
    "From writing a review question to publishing the review results.",
    "View more",
    "PICO guide (Portuguese)",
    "A compact user guide",
    "RoB-Syrcle guide (Portuguese)",
    "User guide to using the PICO mnemonic tool for writing a review question, literature search, and study selection.",
    "/en/tools-and-resources"
  ],
}

export default function AboutGuides({ language }) {
  const text = texts[language]

  return (
    <section className="d-flex align-items-center justify-content-center pt-5 mt-5 container">
      <div className="row">
        <div className="col-lg-4 pb-3">
          <h1 className="h2 fw-normal pb-4">{text[0]}</h1>
          <p className="text-muted">{text[1]}</p>
          <Link to={text[7]} className="text-decoration-none">
            <SecondaryButton>{text[2]}</SecondaryButton>
          </Link>
        </div>
        <div className="col-1"></div>
        <div className="col-lg-7">
          <div className="row d-flex align-items-center justify-content-center">
            <div className="col-12 col-sm-6 d-flex align-items-center justify-content-center">
              <a
                className="text-decoration-none"
                rel="noopener noreferrer"
                target='_blank'
                href="https://mfr.osf.io/render?url=https://osf.io/t5w8y/?direct%26mode=render%26action=download%26mode=render"
              >
                <SingleCard>
                  <div className="col-4 d-flex align-items-center justify-content-center mr-0">
                    <StaticImage
                      src="../../../assets/images/icons/globe.png"
                      alt="globe-icon"
                    />
                  </div>
                  <h4 className="pt-4 text-center">{text[3]}</h4>
                  <p className="text-center text-secondary">{text[4]}</p>
                </SingleCard>
              </a>
            </div>

            <div className="col-12 col-sm-6 d-flex align-items-center justify-content-center">
              <a
                className="text-decoration-none"
                rel="noopener noreferrer"
                target='_blank'
                href="https://mfr.osf.io/render?url=https://osf.io/9mzr2/?direct%26mode=render%26action=download%26mode=render"
              >
                <SingleCard>
                  <div className="col-4 d-flex align-items-center justify-content-center mr-0">
                    <StaticImage
                      src="../../../assets/images/icons/site-globe.png"
                      alt="site-globe-icon"
                    />
                  </div>
                  <h4 className="pt-4 text-center">{text[5]}</h4>
                  <p className="text-center text-secondary">{text[6]}</p>
                </SingleCard>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
