import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import SecondaryButton from "../../buttons/secondary_button"

const texts = {
  pt: [
    "Uma iniciativa Brasileira no campo da Farmacologia",
    "Systematic Pharmacology - SysPhar - é uma iniciativa educacional por CAMARADES BR para apoiar revisões sistemáticas no campo da Farmacologia",
    "Um guia compacto em português",
    "Etapas organizadas em sequência: da pergunta da revisão até a publicação dos resultados",
    "Ferramentas, referências, links e exemplos relevantes para farmacologistas",
    "Conteúdo colaborativo, quer fazer parte? Envie uma mensagem",
    "Ver mais",
    "/sysphar",
  ],
  en: [
    "A Brazilian initiative in the field of Pharmacology",
    "Systematic Pharmacology - SysPhar - is an educational initiative by CAMARADES BR to support systematic reviews in the field of Pharmacology.",
    "A compact guide in Portuguese",
    "Steps organized in sequence: from the review question to the publication of the results.",
    "Tools, references, links and examples relevant to pharmacologists.",
    "It is a collaborative content. Do you want to contribute? Send us message.",
    "See more",
    "/en/sysphar",
  ],
}

export default function AboutSysphar({ language }) {
  const text = texts[language]

  return (
    <section className="d-flex align-items-center bg-light about-sysphar">
      <div className="container">
        <div className="row">
          <div className="d-flex flex-column justify-content-around col-lg-6">
            <h1 className="h2 fw-normal">{text[0]}</h1>
            <p className="text-muted">{text[1]}</p>
            <ul>
              <li className="one">{text[2]}</li>
              <li className="two">{text[3]}</li>
              <li className="three">{text[4]}</li>
              <li className="four">
                <a href="#contact" className="text-decoration-none">
                  {text[5]}
                </a>
              </li>
            </ul>
            <div className="d-flex justify-content-start">
              <Link to={text[7]} className="text-decoration-none pb-3">
                <SecondaryButton>{text[6]}</SecondaryButton>
              </Link>
            </div>
          </div>
          <div className="col-lg-6 order-1 order-lg-2 hero-img">
            <StaticImage
              src="../../../assets/images/generics/sysphar-lab.png"
              alt="Lab"
            />
          </div>
        </div>
      </div>
    </section>
  )
}
