import React from "react"
import "./styles.css"
import PropTypes from "prop-types"

const SingleCard = ({ children }) => {
  return (
    <div className="single-card d-flex flex-column align-items-center justify-content-center">
      {children}
    </div>
  )
}

SingleCard.propTypes = {
  children: PropTypes.node.isRequired,
}

SingleCard.defaultProps = {}

export default SingleCard
