import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import PrimaryButton from "../../buttons/primary_button"

const texts = {
  pt: [
    "Conheça o Wiki",
    "Saiba desde o que é uma revisão sistemática pré-clínica, quais são as suas etapas, até como concluí-las. Uma iniciativa por CAMARADES Berlim. A tradução por CAMARADES BR foi financiada pela fundação Alexander von Humboldt.",
    "Um guia completo para sua revisão. ",
    "Ver mais",
    "Conteúdo colaborativo, quer fazer parte? Envie uma mensagem.",
    "/wiki",
  ],
  en: [
    "Discover Wiki",
    "Learn how and why we perform systematic review of preclinical studies. It is an initiative by CAMARADES Berlin translated to Portuguese by CAMARADES Br. Translation funded by the Alexander von Humboldt foundation.",
    "A complete guide for your systematic review.",
    "View more",
    "It is a collaborative content. Do you want to contribute? Send us message.",
    "/en/wiki",
  ],
}

export default function AboutWiki({ language }) {
  const text = texts[language]

  return (
    <section className="cta d-flex align-items-center">
      <div className="container">
        <div className="row">
          <div className="col-md-6 d-flex flex-column justify-content-around">
            <div>
              <h1 className="display-2 text-white">{text[0]}</h1>
              <p>{text[1]}</p>
            </div>
            <div className="wiki-rectangle d-flex">
              <span className="col d-flex align-items-center justify-content-center text-white px-2">
                {text[2]}
              </span>
              <div className="col d-flex align-items-center justify-content-center">
                <Link to={text[5]} className="text-decoration-none">
                  <PrimaryButton>{text[3]}</PrimaryButton>
                </Link>
              </div>
            </div>
            <div>
              <p>
                <a href="#contact" className="text-white text-decoration-none">
                  {text[4]}
                </a>
              </p>
            </div>
          </div>
          <div className="col-md-6">
            <StaticImage
              src="../../../assets/images/generics/wiki.png"
              alt="Wiki"
            />
          </div>
        </div>
      </div>
    </section>
  )
}
