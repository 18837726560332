import { Link } from "gatsby"
import React from "react"
import PrimaryButton from "../../buttons/primary_button"
import SecondaryButton from "../../buttons/secondary_button"
import WelcomingCards from "./welcoming_cards"

const texts = {
  pt: [
    "Olá, somos CAMARADES Brasil!",
    "Collaborative Approach to Meta-Analysis and Review of Animal Data from Experimental Studies",
    "Nosso site oferece ferramentas úteis para sua revisão sistemática de literatura.",
    "Saiba mais sobre nós",
    "Conheça o nosso blog",
  ],
  en: [
    "Hello, we are CAMARADES Brazil!",
    "Collaborative Approach to Meta-Analysis and Review of Animal Data from Experimental Studies",
    "Our website offers useful tools for your systematic literature review.",
    "Learn more about us",
    "Discover the blog",
  ],
}

const links = {
  pt: ["/sobre-a-camarades", "/blog"],
  en: ["/en/about-camarades", "/en/blog"],
}

export default function Welcome({ language }) {
  const text = texts[language]
  const link = links[language]

  return (
    <section
      id="hero"
      className="d-flex align-items-center globe-bg pb-0 mb-0 fw-bold"
    >
      <div className="container">
        <div className="row">
          <div className="text-center pt-5">
            <h1 className="mt-5 display-4">{text[0]}</h1>
            <h2 className="text-muted">{text[1]}</h2>
            <h3>{text[2]}</h3>
            <div className="row my-4 py-4">
              <div className="d-flex justify-content-center col-6">
                <Link to={link[0]} className="text-decoration-none">
                  <SecondaryButton>{text[3]}</SecondaryButton>
                </Link>
              </div>
              <div className="d-flex justify-content-center col-6">
                <Link to={link[1]} className="text-decoration-none">
                  <PrimaryButton>{text[4]}</PrimaryButton>
                </Link>
              </div>
            </div>
          </div>
          <WelcomingCards language={language} />
        </div>
      </div>
    </section>
  )
}
