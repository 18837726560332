import PropTypes from "prop-types"
import React from "react"
import { Button } from "react-bootstrap"
import "../buttons.css"

const SecondaryButton = ({ children }) => {
  return (
    <Button className="secondary-button">
      <span>{children}</span>
    </Button>
  )
}

SecondaryButton.propTypes = {
  children: PropTypes.node.isRequired,
}

SecondaryButton.defaultProps = {}

export default SecondaryButton
