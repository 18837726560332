import { graphql, Link, useStaticQuery } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { Carousel } from "react-bootstrap"

const texts = {
  pt: [
    "Conheça os Projetos e Revisões Sistemáticas da literatura que estão em andamento na CAMARADES Brasil",
    "Autores",
    "/portfolio-e-iniciativas",
  ],
  en: [
    "Read about projects CAMARADES BR is involved in",
    "Authors",
    "/en/portfolio-and-initiatives",
  ],
}

function CarouselProjects({ language }) {
  const text = texts[language]

  const { allMarkdownRemark } = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark(
          filter: { fileAbsolutePath: { regex: "/(markdown/projects)/" } }
        ) {
          edges {
            node {
              id
              frontmatter {
                titulo
                titulo_en
                descricao
                autores
              }
            }
          }
        }
      }
    `
  )

  return (
    <div className="pt-5 container">
      <h1 className="h2 text-center fw-normal py-5">{text[0]}</h1>
      <Link to={text[2]}>
        <Carousel variant="dark" indicators={false} id="carousel-projects">
          {allMarkdownRemark.edges.map((a, i) => (
            <Carousel.Item key={i}>
              <div className="row">
                <div className="col-md-12 col-lg-6">
                  <StaticImage
                    src={
                      "../../../assets/images/generics/carousel-placeholder.png"
                    }
                    alt={a.node.frontmatter.titulo}
                  />
                </div>
                <div className="col-md-12 col-lg-6">
                  {language === "pt" ? (
                    <h3 className="fw-normal">{a.node.frontmatter.titulo}</h3>
                  ) : (
                    <h3 className="fw-normal">
                      {a.node.frontmatter.titulo_en}
                    </h3>
                  )}
                  <p className="text-grey-20">{a.node.frontmatter.descricao}</p>
                  <p className="text-grey-20">
                    {text[1]}: {a.node.frontmatter.autores}
                  </p>
                </div>
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
      </Link>
    </div>
  )
}
export default CarouselProjects
