import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"

const texts = {
  pt: [
    {
      title: "Systematic Review Facility (SyRF)",
      description: "Guia de uso da SyRF em Português. SyRF é uma plataforma online grátis para revisões sistemáticas de estudos pré-clínicos. Uma iniciativa CAMARADES Edimburgo, financiada pela NC3Rs.",
      isExternal: false,
      link: "/guia-syrf",

    },
    {
      title: "Systematic Pharmacology (SysPhar)",
      description: "SysPhar é uma iniciativa CAMARADES BR para apoiar revisões sistemáticas no campo da Farmacologia financiada pela fundação Alexander von Humboldt.",
      isExternal: false,
      link: "/sysphar",

    },
    {
      title: "E-learning CAMARADES",
      description: "Vídeo-aula (Inglês) por CAMARADES Edimburgo sobre como e porque fazer uma revisão sistemática.",
      isExternal: true,
      link: "https://youtu.be/iQIBMmIAMGQ",

    },
  ],
  en: [
    {
      title: "Systematic Review Facility (SyRF)",
      description: "SyRF user guide in Portuguese. SyRF is a free-to-use online platform for performing systematic reviews of preclinical studies. It is an initiative by CAMARADES, funded by the NC3Rs",
      link: "/en/syrf-guide",
      isExternal: false,
    },
    {
      title: "Systematic Pharmacology (SysPhar)",
      description: "SysPhar is an initiative by CAMARADES BR to support systematic reviews in the field of Pharmacology, funded by the Alexander von Humboldt foundation.",
      link: "/en/sysphar",
      isExternal: false,
    },
    {
      title: "E-learning CAMARADES",
      description: "Video lectures (English) by CAMARADES Edinburgh Team: learn how and why we perform systematic review of animal studies.",
      link: "https://youtu.be/iQIBMmIAMGQ",
      isExternal: true,
    },
  ],
}

export default function WelcomingCards({ language }) {
  return (
    <div className="welcoming-cards">
      <div className="row align-items-center justify-content-center">
        {texts[language].map((el, i) => (
          <div key={i} className="col-sm-6 col-md-4 col-12">
            {el.isExternal ? (
              <a
                href={el.link}
                className="text-decoration-none"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="row align-items-center justify-content-center">
                  <div className="col-4 d-flex align-items-center justify-content-center mr-0">
                    <StaticImage
                      src="../../../assets/images/icons/globe.png"
                      alt="globe-camarades"
                    />
                  </div>
                  <div className="col-8">
                    <h4 className="fw-normal">{el.title}</h4>
                    <p>{el.description}</p>
                  </div>
                </div>
              </a>
            ) : (
              <Link to={el.link} className="text-decoration-none">
                <div className="row align-items-center justify-content-center">
                  <div className="col-4 d-flex align-items-center justify-content-center mr-0">
                    <StaticImage
                      src="../../../assets/images/icons/globe.png"
                      alt="globe-camarades"
                    />
                  </div>
                  <div className="col-8">
                    <h4 className="fw-normal">{el.title}</h4>
                    <p>{el.description}</p>
                  </div>
                </div>
              </Link>
            )}
          </div>
        ))}
      </div>
    </div>
    //     <div className="row m-0 d-flex align-items-center flex-column justify-content-center">
    //     {
    //         arr.map((el, i) => (
    //             <div key={i} className="welcoming-cards col-lg-4 col-md-6 col-sm-12">
    //                 <div className="row align-items-center justify-content-center">
    //                     <div className="col-4 d-flex align-items-center justify-content-center mr-0">
    //                         <StaticImage src='../../../assets/images/icons/globe.png' />
    //                     </div>
    //                     <div className="col-8">
    //                         <h4 className='fw-normal'>{el.title}</h4>
    //                         <p>{el.description}</p>
    //                     </div>
    //                 </div>
    //             </div>
    //         ))
    //     }
    // </div>
  )
}
